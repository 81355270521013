import React, {useContext} from "react";
import PlatformFormContext from "../../components/platform/platformFormContext";
import usePlatformGenericField from "../../components/hook/usePlatformGenericField";
import usePlatformForm from "../../components/hook/usePlatformForm";
import CheckoutContext from "./checkoutContext";

export const CheckoutPaymentFormContext = React.createContext({});
export default CheckoutPaymentFormContext;

export const CheckoutPaymentFormContextProvider = (props) => {
    const {children, onChange} = props;
    const context = useContext(PlatformFormContext);
    const { value, changeHandler } = usePlatformGenericField({ ...props, unbound: "false", context });

    usePlatformForm(200);

    const setOptionType = (optionType) => {
        changeHandler(optionType);

        if (typeof onChange === "function") {
            onChange(optionType);
        }
    }

    return (
        <CheckoutPaymentFormContext.Provider
            value={{
                optionType: value,
                setOptionType: setOptionType
            }}
        >
            <>{children}</>
        </CheckoutPaymentFormContext.Provider>
    );
}