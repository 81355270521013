import * as React from 'react';
import {useContext, useEffect} from 'react';
import Typography from "@mui/material/Typography";
import PlatformSpace from "../../components/platform/platformSpace";
import {CircularProgress, Stack} from "@mui/material";
import CheckoutStatus from "./checkoutStatus";
import {isMobile} from "react-device-detect";
import CheckoutContext from "./checkoutContext";
import CheckoutShippingForm from "./checkoutShippingForm";
import {CheckoutShippingFormContextProvider} from "./checkoutShippingFormContext";
import Button from "@mui/material/Button";
import usePlatformForm from "../../components/hook/usePlatformForm";

export default function CheckoutStep2Shipping(props) {
    const {
        onShippingQuote,
        shippingQuote,
        loadingShipping,
        addressValidated,
        setRecommendedAddressOpen,
        defaultServiceToken
    } = useContext(CheckoutContext);

    useEffect(() => {
        if (isMobile) {
            if (addressValidated === "VALID") {
                setTimeout(() => {
                    onShippingQuote();
                }, 420)
            }
        }
    }, [addressValidated]);

    const onVerifyAddress = () => {
        setRecommendedAddressOpen(true);
    }

    return (
      <React.Fragment>
          {!isMobile &&
            <React.Fragment>
                <CheckoutStatus/>
                <PlatformSpace height={40}/>
            </React.Fragment>
          }
          <Typography variant="h8">
              Shipping method
          </Typography>
          <PlatformSpace height={10}/>
          {addressValidated === "RECOMMENDATION" &&
            <React.Fragment>
                <Button onClick={onVerifyAddress}>
                    Verify address
                </Button>
            </React.Fragment>
          }
          <CheckoutShippingFormContextProvider name="serviceToken" defaultValue={defaultServiceToken}>
              {!loadingShipping &&
                shippingQuote?.rates?.map((rate) => {
                    return (
                      <CheckoutShippingForm key={rate.serviceToken} name={rate.serviceToken} rate={rate}/>
                    )
                })
              }
          </CheckoutShippingFormContextProvider>
          {loadingShipping &&
            <Stack justifyContent="center" direction="row" alignItems="center" spacing={2}>
                <CircularProgress color="inherit" size="5rem"/>
            </Stack>
          }
      </React.Fragment>
    )
}
