import * as React from 'react';
import {useContext} from 'react';
import {CircularProgress, Stack, Typography} from "@mui/material";
import {styled} from "@mui/styles";
import CheckoutSummary from "./checkoutSummary";
import CheckoutStep2Shipping from "./checkoutStep2Shipping";
import CheckoutStep3Payment from "./checkoutStep3Payment";
import CheckoutContext from "./checkoutContext";
import CheckoutStep1Information from "./checkoutStep1Information";
import useLayoutOptions from "../../components/hook/useLayoutOptions";
import PlatformSpace from "../../components/platform/platformSpace";
import CheckoutControls from "./checkoutControls";
import CheckoutHeader from "./checkoutHeader";
import CheckoutSummaryMobile from "./checkoutSummaryMobile";
import usePlatformForm from "../../components/hook/usePlatformForm";

export default function CheckoutHomeMobile(props) {
    const {processing} = useContext(CheckoutContext);

    usePlatformForm();

    useLayoutOptions({
        headerComponent: null,
        footerComponent: null,
        saleComponent: null
    });

    return (
        <React.Fragment>
            {processing &&
                <PaneContainer justifyContent="center" alignItems="center">
                    <PlatformSpace height={170}/>
                    <Typography variant="h3" textAlign="center">
                        Processing order...
                    </Typography>
                    <PlatformSpace height={30}/>
                    <CircularProgress color="inherit" size="5rem" />
                </PaneContainer>}
            {!processing &&
                <PaneContainer>
                    <PlatformSpace height={30}/>
                    <CheckoutHeader/>
                    <PlatformSpace height={15}/>
                    <CheckoutSummaryMobile/>
                    <PlatformSpace height={30}/>
                    <CheckoutStep1Information/>
                    <PlatformSpace height={60}/>
                    <CheckoutStep2Shipping/>
                    <PlatformSpace height={60}/>
                    <CheckoutStep3Payment/>
                    <PlatformSpace height={60}/>
                    <Typography variant="h8">
                        Order summary
                    </Typography>
                    <PlatformSpace height={15}/>
                    <CheckoutSummary/>
                    <PlatformSpace height={30}/>
                    <CheckoutControls/>
                </PaneContainer>
            }
        </React.Fragment>
    )
}

const PaneContainer = styled(Stack)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    width: "88vw",
}));

const LeftPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingRight: 70,
    width: "35vw",
    marginLeft: "auto"
}));

const RightPane = styled("div")(({ theme }) => ({
    paddingTop: 80,
    paddingLeft: 45,
    width: "45vw",
    height: "100vh"
}));